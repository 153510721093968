var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v("Raumverwaltung")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    "Verwalte Buchungen deiner Räume auch aus anderen Quellen auf einfache Art an einem zentralen Platz."
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Auch externe Buchungen und Kunden können schnell und übersichtlich eingetragen und verwaltet werden."
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Die Verwaltung von externen Buchungen ist kostenfrei."
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v(" Unkomplizierter als eine eigene Webseite ")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    " Mit wenigen Handgriffen kannst du deine Räume ansprechend präsentieren und finden lassen."
                  ),
                  _c("br"),
                  _vm._v(
                    " Du bestimmst die Preise, Zusatzoptionen und verfügbare Termine. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Jeder Raum ist durch eine eigene, individualisierbare Url ansprechbar"
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v("Einrichtungsservice")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    "Wenn du Probleme mit der Einrichtung deiner Räume hast sind wir für dich ansprechbar."
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Gerne helfen wir dir dabei, den Inhalt deiner Webseite hier wiederzugeben."
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v("Gestaltungsmöglichkeiten")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    "Fehlt dir eine Funktion? Können wir etwas besser machen?"
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Gerne nehmen wir dein Feedback auf und verbessern die Seite."
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v("Komplett integriert")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    " Nach der Einrichtung läuft der Rest automatisch. Die Kunden finden deine Räume mit einem freien Termin und buchen direkt. Du wirst automatisch benachrichtigt. "
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v("Volle Kostenkontrolle")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    "Registrierung, Einrichtung und Präsentation sind kostenlos."
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Kosten fallen nur an, wenn ein Kunde bei dir bucht und bezahlt."
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticClass: "lead" }, [
                _vm._v("Auswertungen")
              ]),
              _c("CCardBody", [
                _c("p", [
                  _vm._v(
                    "Endlich hast du übersichtlich deine Buchungen, Kunden und Räume auf einer Seite im Blick."
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }